import React, { useState, useEffect, useCallback, useMemo } from 'react';
import debounce from 'lodash.debounce';
import { FixedSizeList } from 'react-window';

import Modal from "react-modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faOctagonXmark } from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownUpAcrossLine } from '@fortawesome/pro-solid-svg-icons';
import { faComment } from '@fortawesome/pro-solid-svg-icons';
import { faChartLine } from '@fortawesome/pro-solid-svg-icons';
import { faHardDrive } from '@fortawesome/pro-solid-svg-icons';
import { faCircle } from '@fortawesome/pro-regular-svg-icons'; // for duotone
import { faCircleQuarterStroke } from '@fortawesome/pro-duotone-svg-icons'; // for duotone 25% circle
import { faCircleHalfStroke } from '@fortawesome/pro-duotone-svg-icons'; // for duotone 50% circle
import { faCircleThreeQuartersStroke } from '@fortawesome/pro-duotone-svg-icons'; // for duotone 75% circle
import { format } from 'date-fns';
import { useTable } from 'react-table';

const cellStyle = {
  whiteSpace: 'normal',
  wordWrap: 'break-word',
};

const ChatModal = (props) => {
  const {
    videoId,
    setShowAllChats,
    closeExpandedRow,
    isModalOpen,
    setIsModalOpen,
    closeModal,
    showAllChats,
    chatFilter,
    setChatFilter
  } = props;
  const [data, setData] = useState([]);
  const [isDataReversed, setIsDataReversed] = useState(false);
  const [debouncedFilter, setDebouncedFilter] = useState(chatFilter);

useEffect(() => {
  const handler = debounce(() => setDebouncedFilter(chatFilter), 300);
  handler();
  return () => handler.cancel();
}, [chatFilter]);


 useEffect(() => {
  const fetchChats = () => {
    if (videoId) {
      fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/get-chats?video_id=${videoId}`)
        .then((response) => response.json())
        .then((jsonData) => {
          let parsedData = JSON.parse(jsonData.results);
          // Reverse the data if isDataReversed is true
          if (isDataReversed) {
            parsedData = parsedData.reverse();
          }
          setData(parsedData);
        })
        .catch((error) => console.error('Error fetching data: ', error));
    } else {
      setData([]);
      setShowAllChats(true);
    }
  };

    fetchChats(); 
    const chatInterval = setInterval(fetchChats, 30000);
    return () => {
      clearInterval(chatInterval);
    };
  }, [videoId]);

useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        closeExpandedRow();
      }
    };
    window.addEventListener('keydown', handleEsc);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [closeExpandedRow]); 


  const handleChatFilterChange = (event) => {
    setChatFilter(event.target.value);
  };

  const reverseData = useCallback(() => {
    setData(prevData => [...prevData].reverse());
    setIsDataReversed(prevState => !prevState);
}, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'timestamp',
        accessor: 'timestamp',
        Cell: ({ value }) => format(new Date(value), 'yyyy-MM-dd HH:mm'),
        width: '20px',
      },
      {
        Header: 'author_name',
        accessor: 'author_name',
        width: '20px',
        Cell: ({ value, row }) => (
          <a href={`https://www.youtube.com/channel/${row.original.author_id}`} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      ),},
      {
        Header: 'message',
        accessor: 'message',
        width: '75px',
      },
    {
      Header: 'value',
      accessor: 'usd_value',
      Cell: ({ value }) => `$${value}`,
      width: '10px',
    }

    ],
    []
  );


const filteredData = useMemo(() => {
  if (!data) return [];
  
  const filterCondition = row => {
    const messageMatch = chatFilter ? row.message.toLowerCase().includes(chatFilter.toLowerCase()) : true;
    const authorMatch = chatFilter ? row.author_name.toLowerCase().includes(chatFilter.toLowerCase()) : true;
    return messageMatch || authorMatch;
  };

  return showAllChats
    ? data.filter(row => row.usd_value > 0 && filterCondition(row))
    : data.filter(filterCondition);
}, [data, showAllChats, chatFilter]);


const ChatsTable = () => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: filteredData,
  });

  return (
    <table className="data" {...getTableProps()} style={{ tableLayout: 'fixed', width: '100%' }}>

    <thead>
      {headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => (
            <th {...column.getHeaderProps()} style={{...cellStyle, width: column.width}}>{column.render('Header')}</th>
          ))}
        </tr>
      ))}
    </thead>
    <tbody {...getTableBodyProps()}>
      {rows.map(row => {
        prepareRow(row);
        let rowStyle = {};

        if (row.original.nuked_bool) {
          rowStyle = { backgroundColor: 'rgba(161, 48, 54, 0.5)' };
        } else if (row.values.usd_value > 0) {
          rowStyle = { backgroundColor: 'rgba(11, 150, 64, 0.5)' };
        }

        return (
          <tr 
            {...row.getRowProps()} 
            style={rowStyle}
          >
            {row.cells.map(cell => (
              <td {...cell.getCellProps()} style={{...cellStyle, width: cell.column.width}}>{cell.render('Cell')}</td>
            ))}
          </tr>
        );
      })}
    </tbody>
  </table>
);

  };



return (
  <div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Chats Modal"
      >
        <div className="modal-header close-container" onClick={closeExpandedRow} style={{ display: 'flex', justifyContent: 'center' }}>
          <FontAwesomeIcon icon={faOctagonXmark} className="fa-thin" style={{ display: 'inline-block', marginRight: '25px' }} />
          <h6 className="modal-title">
            click here or hit escape to close
          </h6>
          <FontAwesomeIcon icon={faOctagonXmark} className="fa-thin" style={{ display: 'inline-block', marginLeft: '25px' }} />
        </div>

        {/* Moved checkbox and search filter to top of modal */}
        <div style={{ textAlign: 'left', padding: '10px 0' }}>
          <input
            type="checkbox"
            checked={showAllChats}
            onChange={(event) => {
              setShowAllChats(event.target.checked);
            }}
            style={{ marginRight: 10 }}  // Add some spacing
          /> superchats-only
          <input
            type="text"
            value={chatFilter}
            onChange={handleChatFilterChange}
            style={{ marginLeft: 10 }}  // Add some spacing
            placeholder="filter author or message..."
          />
        </div>

        {/* New div for reverse order icon */}
        <div className="modal-header reverse-container" style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <FontAwesomeIcon icon={faArrowDownUpAcrossLine} className="fa-thin" style={{ color: 'red', display: 'inline-block' }} onClick={reverseData} />
        </div>

        <div style={{ height: '525px', overflowY: 'auto' }}>
          <ChatsTable />
        </div>
      </Modal>
  </div>
);
};

export default ChatModal;