import React, { useState, useEffect, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { AuthContext, AuthProvider } from '../../AuthContext'; 
import '../../css/animation.css';

Chart.register(...registerables);

const timeRanges = {
  '7 day': 7,
  '14 day': 14,
  '30 day': 30,
  '60 day': 60,
  '90 day': 90,
  '120 day': 120,
  '180 day': 180,
  '365 day': 365,
  '730 day': 730
};

const movingAverageRanges = {
  'select mov. avg': 0,
  '3 day moving avg': 3,
  '7 day moving avg': 7,
  '14 day moving avg': 14,
  '30 day moving avg': 30,
  '90 day moving avg': 90,
};

const calculateMovingAverage = (data, range = 7) => {
  let movingAverages = [];
  // Check if there are enough data points to calculate the moving average
  if (data.length >= range) {
    for (let i = range - 1; i < data.length; i++) {
      let sum = 0;
      for (let j = 0; j < range; j++) {
        sum += data[i - j];
      }
      movingAverages.push(sum / range);
    }
  } else {
    // Handle case when there isn't enough data
    // (you can customize this logic)
    movingAverages = new Array(data.length).fill(null);
  }
  return movingAverages;
};

const AnalyzeRevenue = () => {
  const { user } = useContext(AuthContext);
  const [dataKeys, setDataKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState(['ac3', 'none', 'none']); // Initialize with 'none' for channels 2 and 3
  const [selectedKey1, setSelectedKey1] = useState('ac3');
  const [selectedKey2, setSelectedKey2] = useState('none');
  const [selectedKey3, setSelectedKey3] = useState('none');
  const [selectedTimeRange, setSelectedTimeRange] = useState('30 day');
  const [fullData, setFullData] = useState([]); // This will store the full dataset
  const [chartData, setChartData] = useState({ datasets: [] });
  const [chartOptions, setChartOptions] = useState({
    scales: {
      y: {
        beginAtZero: true,
        responsive: true
      },
    },
  });

  const [movingAverageRange, setMovingAverageRange] = useState(movingAverageRanges['7 day']);

  const [channelSumData, setChannelSumData] = useState({
    [selectedKey1]: 0,
    [selectedKey2]: 0,
    [selectedKey3]: 0
  });

  const calculateChannelSum = (data, channelKey, daysToDisplay) => {
    let sum = 0;
    for (let i = data.length - 1; i >= 0 && daysToDisplay > 0; i--) {
      sum += data[i][channelKey];
      daysToDisplay--;
    }
    return sum.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  function handleSelectChange(e, index) {
  const newSelectedKeys = [...selectedKeys];
  newSelectedKeys[index] = e.target.value;

  setSelectedKeys(newSelectedKeys);
  if(index === 0) setSelectedKey1(e.target.value);
  else if(index === 1) setSelectedKey2(e.target.value);
  else if(index === 2) setSelectedKey3(e.target.value);
}

  // Fetch the full data once
  useEffect(() => {
    const fetchData = async () => {
      const url = 'https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/superchats';
      const params = {
        option: '365_day_summary',
        clientip: '127.0.0.1',
        username: user,
      };

      try {
        const response = await fetch(`${url}?${new URLSearchParams(params)}`);
        const jsonData = await response.json();

        // Assuming jsonData is the direct array from API response
        const parsedData = jsonData.map(item => ({
          ...item,
          date: new Date(item.date).toLocaleDateString('en-CA'), // Format date
        })).reverse(); // Reverse the data order
        
        setFullData(parsedData);
        const keys = Object.keys(parsedData[0]);
        setDataKeys(keys.filter(key => key !== 'date')); // Filter out the 'date' key
      } catch (error) {
        console.error('Error fetching line chart data: ', error);
      }
    };

    fetchData().then(() => {
    setDataKeys(prevKeys => ['none', ...prevKeys.filter(key => key !== 'date')]);
  });
  }, []); 

  useEffect(() => {
    setSelectedKeys([selectedKey1, selectedKey2, selectedKey3]);
  }, [selectedKey1, selectedKey2, selectedKey3]);

  // Filter and set chart data based on selected keys and time range
useEffect(() => {
  const daysToDisplay = timeRanges[selectedTimeRange];
  const endDate = new Date();
  const startDate = new Date();
  startDate.setDate(endDate.getDate() - daysToDisplay);

  const filteredData = fullData.filter(item => {
    const itemDate = new Date(item.date);
    return itemDate >= startDate && itemDate <= endDate;
  });

  const sum1 = calculateChannelSum(filteredData, selectedKeys[0], daysToDisplay);
  const sum2 = calculateChannelSum(filteredData, selectedKeys[1], daysToDisplay);
  const sum3 = calculateChannelSum(filteredData, selectedKeys[2], daysToDisplay);

  setChannelSumData({
    [selectedKeys[0]]: sum1,
    [selectedKeys[1]]: sum2,
    [selectedKeys[2]]: sum3
  });

    const movingAverage1 = calculateMovingAverage(filteredData.map(item => item[selectedKey1]), movingAverageRange);
    const movingAverage2 = calculateMovingAverage(filteredData.map(item => item[selectedKey2]), movingAverageRange);
    const movingAverage3 = calculateMovingAverage(filteredData.map(item => item[selectedKey3]), movingAverageRange);



  const sliceValue = Math.min(daysToDisplay - 1, filteredData.length - 1);

  const datasets = selectedKeys.map((key, index) => {
  
  // Define base hue for each key. Distribute hues evenly across 360 degrees.
  const baseHue = 360 * index / selectedKeys.length;

  return {
    label: key,
    data: filteredData.map(item => item[key]),
    fill: false,
    // Use HSL to define color. Saturation and lightness are fixed, hue changes for each key
    borderColor: `hsl(${baseHue}, 35%, 40%)`, // Modify the saturation and lightness as needed
  };
});

if (movingAverageRange !== 'none') {
  const movingAverages = selectedKeys.map(key =>
    calculateMovingAverage(filteredData.map(item => item[key]), movingAverageRange)
  );

  movingAverages.forEach((average, index) => {
    if (average.length) {
      // Reuse the base hue for the moving average
      const baseHue = 360 * index / selectedKeys.length;

      datasets.push({
        label: `${selectedKeys[index]} ${movingAverageRange}-day MA`,
        data: average,
        fill: false,
        // Increase lightness to make the moving average color brighter
        borderColor: `hsl(${baseHue}, 35%, 65%)`, // Increase lightness by 15%
        borderDash: [5, 5]
      });
    }
  });
}


//console.log("Filtered Data:", filteredData);
//console.log("Moving Average 1:", movingAverage1);
//console.log("Moving Average 2:", movingAverage2);

  setChartData((prevChartData) => ({
    ...prevChartData,
    labels: filteredData.map(item => item.date).slice(-daysToDisplay), // This will ensure labels are not empty
    datasets
  }));
}, [selectedKeys, selectedTimeRange, fullData, movingAverageRange]);



  //console.log('chartData:',chartData);
  //console.log(`The currently logged in user is: ${user}`);

  return (
    <div style={{ width: '100%', height: 'auto', maxWidth: '1200px', margin: 'auto' }}>
      <br />
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
{selectedKeys.map((selectedKey, index) => (
  <div style={{ marginRight: '15px' }} key={index}>
    <select value={selectedKey} onChange={(e) => handleSelectChange(e, index)}>
      {dataKeys.map((key) => (
        <option key={key} value={key}>{key}</option>
      ))}
    </select>
  </div>
        ))}
        {/* Moving Average Range Selector */}
        <div style={{ marginRight: '25px' }}>
          <select value={movingAverageRange} onChange={(e) => setMovingAverageRange(e.target.value)}>
            {Object.entries(movingAverageRanges).map(([label, value]) => (
              <option key={label} value={value}>{label}</option>
            ))}
            <option value="none">none</option> {/* Option to select "none" for moving averages */}
          </select>
        </div>
        {Object.keys(timeRanges).map((range) => (
          <div style={{ marginRight: '10px' }}>
            <button
              key={range}
              onClick={() => setSelectedTimeRange(range)}
              style={{ backgroundColor: selectedTimeRange === range ? 'green' : 'black' }}
            >
              {range}
            </button>
          </div>
        ))}
      </div>
      <br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {selectedKeys.map((selectedKey, index) => (
          <div style={{ marginRight: '20px' }} key={index}>
            {selectedKey}: {channelSumData[selectedKey]}
          </div>
        ))}
      </div>
      <br />
      <div style={{ width: '100%', height: 'auto', maxWidth: '1200px', margin: 'auto' }}>
        <Line data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default AnalyzeRevenue;
