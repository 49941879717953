import React, { useState, useEffect } from 'react';
import '../../css/tables.css';
import {
  handleYesterdayClick,
  handleSevenDaysClick,
  handleThirtyDaysClick,
  handleOneYearClick,
  handleAllTimeClick,
  handleInputChange,
  handleInputChange2,
  handleSearchClick,
  handleKeyPress,
  formatDate
} from './components/Handlers.js';

const TranscriptSearch = ({ onSearch }) => {
  const today = new Date();
  const sevenDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30);
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState('all');
  const [startDate, setStartDate] = useState(formatDate(sevenDaysAgo));
  const [endDate, setEndDate] = useState(formatDate(today));
  const [activeButton, setActiveButton] = useState('30-day');
  const [inputText, setInputText] = useState('');
  const [inputText2, setInputText2] = useState('');
  const [runSearch, setRunSearch] = useState(false);

/*  useEffect(() => {
    const storedChannelData = JSON.parse(localStorage.getItem('channel-data') || '{"results":[]}');
    const channelDataArray = storedChannelData.results;
    console.log('channel data:', channelDataArray);
    
    const categorizedChannels = channelDataArray.reduce((acc, channel) => {
      const [id, name, nickname] = channel;
      if (!nickname) return acc;
      
      let category = 'Other';
      if (nickname.startsWith('archie') || nickname === 'dungeon' || nickname === 'paulplutaprestige') {
        category = 'Archie';
      } else if (nickname.startsWith('wn')) {
        category = 'Watch Nicholas';
      } else if (['timmy', 'community', 'ocean', 'ibashers'].includes(nickname)) {
        category = 'Timmy';
      } else if (['tpg', 'timepiecevideos', 'gc', 'paulthorpe'].includes(nickname)) {
        category = 'Gray Marketeers';
      }
      
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push({ id, name, nickname });
      return acc;
    }, {});

    setChannels(categorizedChannels);
  }, []);*/

useEffect(() => {
  const storedChannelData = JSON.parse(localStorage.getItem('channel-data') || '{"results":[]}');
  const channelDataArray = storedChannelData.results;
  console.log('channel data:', channelDataArray);

  const sortedChannels = channelDataArray
    .filter(channel => channel[2] && channel[8] === 'horology') // Filter for nickname and horology brand
    .map(([id, name, nickname]) => ({ id, name, nickname })) // Convert to objects
    .sort((a, b) => a.nickname.localeCompare(b.nickname)); // Sort by nickname
    
  const categorizedChannels = {
    ' --- ': sortedChannels
  };
    
  setChannels(categorizedChannels);
}, []);

  useEffect(() => {
    if (runSearch) {
      handleSearchClick(inputText, inputText2, selectedChannel, startDate, endDate, onSearch);
      setRunSearch(false);
    }
  }, [runSearch, inputText, inputText2, selectedChannel, startDate, endDate, onSearch]);

  return (
    <div className="transcript-search-outer-container">
      <div className="transcript-search-container">
        <div className="date-buttons">
          <button 
            className={activeButton === '24-hour' ? 'active' : ''}
            onClick={() => handleYesterdayClick(setStartDate, setEndDate, setActiveButton, setRunSearch)}
          >
            24-hour
          </button>
          <button 
            className={activeButton === '7-day' ? 'active' : ''}
            onClick={() => handleSevenDaysClick(setStartDate, setEndDate, setActiveButton, setRunSearch)}
          >
            7-day
          </button>
          <button 
            className={activeButton === '30-day' ? 'active' : ''}
            onClick={() => handleThirtyDaysClick(setStartDate, setEndDate, setActiveButton)}
          >
            30-day
          </button>
          <button 
            className={activeButton === '365-day' ? 'active' : ''}
            onClick={() => handleOneYearClick(setStartDate, setEndDate, setActiveButton)}
          >
            365-day
          </button>
          <button 
            className={activeButton === 'all-time' ? 'active' : ''}
            onClick={() => handleAllTimeClick(setStartDate, setEndDate, setActiveButton)}
          >
            all-time
          </button>
        </div>

        <input
          type="text"
          value={inputText}
          onChange={(e) => handleInputChange(e, setInputText)}
          className="search-input"
          placeholder="search query"
          onKeyPress={(e) => handleKeyPress(e, () => handleSearchClick(inputText, inputText2, selectedChannel, startDate, endDate, onSearch))}
        />
        <input
          type="text"
          value={inputText2}
          onChange={(e) => handleInputChange2(e, setInputText2)}
          className="search-input"
          placeholder="AND query"
          onKeyPress={(e) => handleKeyPress(e, () => handleSearchClick(inputText, inputText2, selectedChannel, startDate, endDate, onSearch))}
        />

        <select
          value={selectedChannel}
          onChange={(e) => setSelectedChannel(e.target.value)}
          className="channel-select"
        >
          <option value="all">search-all-channels</option>
          {Object.entries(channels).map(([category, channelList]) => (
            <React.Fragment key={category}>
              <option disabled>---{category}---</option>
              {channelList.map(channel => (
                <option key={channel.id} value={channel.nickname}>
                  {channel.nickname.replace(/-/g, ' ')}
                </option>
              ))}
            </React.Fragment>
          ))}
        </select>

        <label htmlFor="start-date"></label>
        <input
          type="date"
          id="start-date"
          value={startDate}
          onChange={(e) => {setStartDate(e.target.value); setActiveButton(null);}}
        />
        <label htmlFor="end-date"></label>
        <input
          type="date"
          id="end-date"
          value={endDate}
          onChange={(e) => {setEndDate(e.target.value); setActiveButton(null);}}
        />

        <button onClick={() => handleSearchClick(inputText, inputText2, selectedChannel, startDate, endDate, onSearch)}>
          search
        </button>
      </div>
    </div>
  );
};

export default TranscriptSearch;