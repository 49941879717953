import React, { useState, useEffect } from 'react';
import { useTable } from 'react-table';
import { format, parseISO } from 'date-fns';
import './css/tables.css'; // Import the CSS file

const cellStyle = {
  whiteSpace: 'normal',
  wordWrap: 'break-word',
};

const formatXAxis = (tickItem) => {
  return format(parseISO(tickItem), 'yyyy-MM-dd');
};

const VideoChats = () => {
  const [data, setData] = useState([]);
  const [videoIdInput, setVideoIdInput] = useState(''); // For input field
  const [videoId, setVideoId] = useState(''); // For fetch request

  useEffect(() => {
    if (videoId) {
      fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/get-chats?video_id=${videoId}`)
        .then((response) => response.json())
        .then((jsonData) => {
          const parsedData = JSON.parse(jsonData.results);
          setData(parsedData);
        })
        .catch((error) => console.error('Error fetching data: ', error));
    }
  }, [videoId]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'timestamp',
        accessor: 'timestamp',
        Cell: ({ value }) => format(new Date(value), 'yyyy-MM-dd HH:mm'),
        width: '25px',
      },
      {
        Header: 'author_id',
        accessor: 'author_id',
        width: '45px',
      },
      {
        Header: 'author_name',
        accessor: 'author_name',
        width: '35px',
      },
      {
        Header: 'message',
        accessor: 'message',
        width: '75px',
      },
      {
        Header: 'usd_value',
        accessor: 'usd_value',
        width: '25px',
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const handleGoClick = () => {
    setVideoId(videoIdInput);
  };

return (
  <div>
    <br />
    <div style={{ textAlign: 'center', marginBottom: '20px', padding: '10px', border: '1px solid black' }}>
      <input
        type="text"
        value={videoIdInput}
        onChange={(e) => setVideoIdInput(e.target.value)}
        placeholder="Enter video ID"
        style={{ padding: '5px', fontSize: '16px' }} // Inline styling for visibility
      />
      <button onClick={handleGoClick} style={{ padding: '5px 10px', fontSize: '16px' }}>GO</button>
    </div>
    <h5 style={{ textAlign: 'center' }}>Chat history for video_id {videoId}</h5>
    <br />
    <div className="search-results-container">
      <div className="scrollbar-container" style={{ height: '500px', overflowY: 'auto' }}>
        <table className="data" {...getTableProps()} style={{ tableLayout: 'fixed', width: '100%' }}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} style={{ ...cellStyle, width: column.width }}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} style={{ ...cellStyle, width: cell.column.width }}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);
};

export default VideoChats;