import { useCallback } from 'react';

export const useHandlers = ({
  user,
  modalIsOpen,
  setModalIsOpen,
  setExpandedRowId,
  setViewerData,
  setIsChartModalOpen,
  setCurrentVideoId,
  setCurrentChannelNickname,
  setShowConfirmModal,
  setShowPermissionModal,
  setShowChatBackloadConfirmModal,
  setShowRegenConfirmModal,
  setShowMigrateConfirmModal,
  setRequestNote,
  setResponseMessage,
  setResponseStatus,
  setArchivalModalOpen,
  setSelectedResult,
  setShowArchivalModal,
  setIsLoading,
  defaultRequestNote,
  setShowForceCompleteConfirmModal,
  setNewStreamId,
  currentVideoId,
  currentChannelNickname,
  newStreamId,
}) => {

  const handleTranscriptGeneration = useCallback((video_id) => {
    const filename = `transcript_${video_id}.txt`; 
    const url = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/get_transcript?videoid=${video_id}&start=1`;

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Trigger the file download
        const fileUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(fileUrl);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  }, []);

  const handleRowClick = useCallback((row) => {
    if (modalIsOpen) {
      setModalIsOpen(false);
    } else {
      setExpandedRowId(row.original.video_id);
      setModalIsOpen(true);
    }
  }, [modalIsOpen, setModalIsOpen, setExpandedRowId]);

  const handleViewersClick = useCallback((video_id) => {
    fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/streams?option=live_statistics&username=${user}&video_id=${video_id}`)
      .then((response) => response.json())
      .then((jsonData) => {
        setViewerData(jsonData.results);
        setIsChartModalOpen(true);
      })
      .catch((error) => console.error('Error fetching viewer data: ', error));
  }, [user, setViewerData, setIsChartModalOpen]);

  const handleRowTrash = useCallback((video_id) => {
    setCurrentVideoId(video_id);
    if (user === 'localUser' || user === 'mookie') {
      setShowConfirmModal(true);
    } else {
      setShowPermissionModal(true);
    }
  }, [user, setCurrentVideoId, setShowConfirmModal, setShowPermissionModal]);

  const handleRowForceComplete = useCallback((video_id) => {
    setCurrentVideoId(video_id);
    if (user === 'localUser' || user === 'mookie') {
      setShowForceCompleteConfirmModal(true);
    } else {
      setShowPermissionModal(true);
    }
  }, [user, setCurrentVideoId, setShowForceCompleteConfirmModal, setShowPermissionModal]);

  const handleBackloadChat = useCallback((video_id) => {
    setCurrentVideoId(video_id);
    if (user === 'localUser' || user === 'mookie') {
      setShowChatBackloadConfirmModal(true);
    } else {
      setShowPermissionModal(true);
    }
  }, [user, setCurrentVideoId, setShowChatBackloadConfirmModal, setShowPermissionModal]);

  const handleRowRegenTranscript = useCallback((video_id, channel_nickname) => {
    setCurrentVideoId(video_id);
    setCurrentChannelNickname(channel_nickname);
    if (user === 'localUser' || user === 'mookie') {
      setShowRegenConfirmModal(true);
    } else {
      setShowPermissionModal(true);
    }
  }, [user, setCurrentVideoId, setCurrentChannelNickname, setShowRegenConfirmModal, setShowPermissionModal]);

  const handleRowMigrate = useCallback((video_id, channel_nickname) => {
    setCurrentVideoId(video_id);
    setCurrentChannelNickname(channel_nickname);
    if (user === 'localUser' || user === 'mookie') {
      setShowMigrateConfirmModal(true);
    } else {
      setShowPermissionModal(true);
    }
  }, [user, setCurrentVideoId, setCurrentChannelNickname, setShowMigrateConfirmModal, setShowPermissionModal]);

  const handleArchivalRequest = useCallback((result) => {
    setSelectedResult(result);
    setRequestNote(defaultRequestNote);
    setResponseMessage('');
    setResponseStatus('');
    setArchivalModalOpen(true);
    setShowArchivalModal(true);
  }, [setSelectedResult, setRequestNote, defaultRequestNote, setResponseMessage, setResponseStatus, setArchivalModalOpen, setShowArchivalModal]);

  const handleArchivalRequestGeneration = useCallback((result, requestNote) => {
    const video_id = result.original.video_id;
    const channel_nickname = result.original.channel_nickname;
    const request_note = requestNote;

    const url = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/request-archival?videoid=${video_id}&username=${user}&channel_nickname=${channel_nickname}&note=${request_note}`;
    setIsLoading(true);

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        const { message, status } = data;
        setResponseMessage(message);
        setResponseStatus(status);
        if (status === 'success') {
          console.log('archival Request successful:', message);
        } else if (status === 'duplicate_video_id') {
          console.log('archival request rejected due to duplicate_video_id');
        } else {
          console.log('archival request failed due to Unknown status:', status);
        }
      })
      .catch((error) => {
        console.error('Error requesting archival:', error);
        setIsLoading(false);
      });
  }, [user, setIsLoading, setResponseMessage, setResponseStatus]);

  const handleMigrateConfirmYes = useCallback(() => {
    const url = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/recover_video?videoid=${encodeURIComponent(currentVideoId)}&channel=${encodeURIComponent(currentChannelNickname)}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log('recover_video, response:', data);
      })
      .catch((error) => console.error('Error recovering the video:', error));
    setShowMigrateConfirmModal(false);
  }, [currentVideoId, currentChannelNickname, setShowMigrateConfirmModal]);

  const handleConfirmYes = useCallback(() => {
    const url = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/trash_video?videoid=${encodeURIComponent(currentVideoId)}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log('nuked, response:', data);
      })
      .catch((error) => console.error('Error trashing the video:', error));
    setShowConfirmModal(false);
  }, [currentVideoId, setShowConfirmModal]);

  const handleBackloadChatYes = useCallback(() => {
    const url = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/backload_chat?videoid=${encodeURIComponent(currentVideoId)}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log('chat backload response:', data);
      })
      .catch((error) => console.error('Error backloading chat object:', error));
    setShowChatBackloadConfirmModal(false);
  }, [currentVideoId, setShowChatBackloadConfirmModal]);

  const handleForceCompleteYes = useCallback(() => {
    const url = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/force_stream_complete?videoid=${encodeURIComponent(currentVideoId)}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log('force stream complete response:', data);
      })
      .catch((error) => console.error('Error forcing stream complete:', error));
    setShowForceCompleteConfirmModal(false);
  }, [currentVideoId, setShowForceCompleteConfirmModal]);

  const handleRegenConfirmYes = useCallback(() => {
    const url = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/regen_video_transcript?channel_nickname=${encodeURIComponent(currentChannelNickname)}&videoid=${encodeURIComponent(currentVideoId)}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log('nuked, response:', data);
      })
      .catch((error) => console.error('Error trashing the video:', error));
    setShowRegenConfirmModal(false);
  }, [currentVideoId, currentChannelNickname, setShowRegenConfirmModal]);

  const handleAddStream = useCallback(() => {
    if (user !== "mookie") {
      setShowPermissionModal(true);
      return;
    }
    fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/add_stream?videoid=${newStreamId}`)
      .then(response => response.json())
      .then(data => {
        console.log('Stream added:', data);
        setNewStreamId('');
      })
      .catch(error => console.error('Error adding stream:', error));
  }, [user, newStreamId, setShowPermissionModal, setNewStreamId]);

  return {
    handleRowClick,
    handleViewersClick,
    handleRowTrash,
    handleRowForceComplete,
    handleBackloadChat,
    handleRowRegenTranscript,
    handleRowMigrate,
    handleArchivalRequest,
    handleArchivalRequestGeneration,
    handleMigrateConfirmYes,
    handleConfirmYes,
    handleBackloadChatYes,
    handleForceCompleteYes,
    handleRegenConfirmYes,
    handleAddStream,
    handleTranscriptGeneration,
    openModal: useCallback(() => setModalIsOpen(true), [setModalIsOpen]),
    closeModal: useCallback(() => setModalIsOpen(false), [setModalIsOpen])
  };
};